import { VIEWERS, DATA_ATTRS } from "./consts";
import { capitalizeFirstLetter } from "./utils";

const getDataAttrName = (name) => `d8s${capitalizeFirstLetter(name)}`;

const getTagParams = (params) => {
	return params ?
		params.split(",").reduce((res, p) => {
			const [key, value] = p.split("=");

			if (key && value) {
				try {
					//numbers and booleans
					res[key] = JSON.parse(value);
				} catch (_) {
					res[key] = value;
				}
			}

			return res;
		}, {}) :
		{};
};

const getPropsFromContainer = (containerElm) =>
	DATA_ATTRS.reduce((res, attr) => {
		res[attr] = containerElm.dataset[getDataAttrName(attr)] || "";

		if (attr === "type") {
			//default to image if not specified
			res[attr] = !res[attr] ? VIEWERS.IMAGE : res[attr].toUpperCase();
		}

		if (attr === "tagParams") {
			res[attr] = getTagParams(res[attr]);
		}

		return res;
	}, {});

const findViewerContainers = ({ containerSelector, rootSelector }) => {
	const containersElms = document.querySelectorAll(`${rootSelector} ${containerSelector}`);

	return Array.prototype.reduce.call(containersElms, (res, container) => {
		const containerProps = getPropsFromContainer(container);

		//increment counter for this type
		res.counters[containerProps.type] ||= 0;
		res.counters[containerProps.type] += 1;

		res.containers[containerProps.type] ||= [];
		res.containers[containerProps.type].push({ container, containerProps });

		return res;
	}, { containers: {}, counters: {} });
};

export default findViewerContainers;
