import { TAG_EVENTS, VIEWERS } from "./consts";
import { warn } from "rtUtils/logger";
import renderWithViewers from "./renderWithViewers";

const lazyLoadViewers = async (updateableConfig, eventsManager) => {
	const viewers = updateableConfig.getConfig().viewers;
	const toLoad = [];

	Object.values(VIEWERS)
		.forEach((type) => {
			if (viewers.includes(type)) {
				toLoad.push(import(/* webpackPreload: true */ (`./viewers/${type.toLowerCase()}`))
						.then((viewerModule) => ({ viewer: viewerModule.default, type })));
			}
		});

	const loadedViewers = await Promise.allSettled(toLoad);

	return loadedViewers.reduce((res, p) => {
		if (p.status === "fulfilled") {
			res[p.value.type] = p.value.viewer(updateableConfig, eventsManager);
		} else {
			warn("Dimensions Tag - Failed to load viewer");
		}

		return res;
	}, {});
};

const initializeTag = async (updateableConfig, eventsManager) => {
	eventsManager.triggerWithAnalytics(TAG_EVENTS.TAG_INIT, updateableConfig.getConfig());

	const viewersPromise = lazyLoadViewers(updateableConfig, eventsManager);

	return viewersPromise
		.then((viewers) => {
			eventsManager.trigger(TAG_EVENTS.TAG_READY);

			return {
				viewers,
				render: () => {
					renderWithViewers(viewers, updateableConfig);
					eventsManager.trigger(TAG_EVENTS.TAG_RENDERED);
				},
				eventsManager,
			};
		});
};

export default initializeTag;

