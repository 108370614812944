export const VIEWERS = Object.freeze({
	IMAGE: "IMAGE",
	VIDEO: "VIDEO",
	THREE_D: "3D",
});

export const BASE_URL = "https://dimensions-art.cloudinary.net/";

export const API_URL = process.env.INFO_API || "https://api.dimensions.cloudinary.com/";

export const ANALYTICS_END_POINT = "https://n902wcigxi.execute-api.us-east-1.amazonaws.com/default/immediate-response-router-function";

export const DATA_ATTRS = ["id", "type", "name", "preset", "urlParams", "tagParams", "from"];

export const TAG_EVENTS = {
	"TAG_INIT": "tag-init",
	"TAG_READY": "tag-ready",
	"TAG_RENDERED": "tag-render-done",
};
