/* eslint no-console: 0 */
import getGlobal from "./getGlobal";

let isDebug =
	process.env.NODE_ENV !== "production" ||
	getGlobal()._d8sDebug === true ||
	getGlobal().location.search.includes("debug");

const setDebug = (debug) => {
	isDebug = debug;
};

const debug = (...args) => {
	if (isDebug) {
		console.log(...args);
	}
};

const warn = (...args) => {
	console.warn(...args);
};

const getIsDebug = () => isDebug;

export {
	debug,
	warn,
	setDebug,
	getIsDebug,
};
