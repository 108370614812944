import isPlainObject from "./isPlainObject";

const isMergeObj = (obj) =>
	isPlainObject(obj) || Array.isArray(obj);

const merge = (target, ...sources) => {
		if (target && sources.length) {
			sources.forEach((source) => {
				if (source) {
					Object.keys(source)
						.forEach((key) => {
							const prop = source[key];

								if (typeof prop !== "undefined") {
									//object/array - go deeper
									if (isMergeObj(prop)) {
										if (typeof target[key] === "undefined" || !isPlainObject(target[key])) {
											//recreate target prop if doesnt exist or not an object
											target[key] = Array.isArray(prop) ? [] : {};
										}

										merge(target[key], prop);
									} else {
										target[key] = prop;
									}
								}
						});
				}
			});
		}

		return target;
	};

export default merge;
